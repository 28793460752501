<template>
  <div :class="containerClass">
      <div :class="dropdownClass">
        <button class="btn btn-sm btn-outline-primary dropdown-toggle" @click.prevent="toggleButton()" type="button">
          <span :class="'language-flag language-flag_' + $i18n.locale"></span>
          {{$i18n.t('languages_real.' + $i18n.locale)}}
        </button>
        <div :class="dropdownMenuClass">
          <template v-for="lang in languages">
            <a
              :key="lang"
              class="dropdown-item language-item"
              v-if="!$i18n.locale.includes(lang)"
              @click.prevent="changeLanguage(lang)"
            >
              <span :class="'language-flag language-flag_' + lang"></span>
              {{$i18n.t('languages_real.' + lang)}}
            </a>
          </template>
        </div>
      </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { AVAILABLE_LANGUAGES } from '@/application/localization/index.js'
import { useChangeLanguage } from '@/application/composables/changeLanguage.js'

const props = defineProps({
  up: {
    type: Boolean,
    required: false,
  },
})
const languages = AVAILABLE_LANGUAGES

let isOpenLanguages = false
const toggleButton = () => {
  isOpenLanguages = !isOpenLanguages
  dropdownClass.value = isOpenLanguages ? 'dropdown language show' : 'dropdown language'
  dropdownMenuClass.value = isOpenLanguages ? 'dropdown-menu language_menu show' : 'dropdown-menu language_menu'
}

const dropdownClass = ref('dropdown')
const dropdownMenuClass = ref('dropdown-menu language_menu')
const changeLanguage = (lang) => {
  changeLanguageValue(lang)
  toggleButton()
}
const containerClass = props.up ? 'row justify-content-center dropup' : 'row justify-content-center'
const { changeLanguageValue } = useChangeLanguage()
</script>
